<template>
  <div class="mobile_upload_way">
    <section class="modal_top">
      <div class="close_btn" @click="$emit('close')">
        <img src="@/assets/img/close-black.png" alt="닫기" />
      </div>
      <p>
        축제 자료를<br />
        지금 업로드 할 수 없다면?
      </p>
    </section>
    <section class="modal_bottom">
      <p>축제 자료를 지금 업로드 할 수 없어도 괜찮습니다!</p>
      <div>
        <p>자료 준비가 끝나면 다시 이 사이트로 와 주세요.</p>
        <p>
          <span>로그인 > MY스탬프 팝 > 축제 자료 등록</span> 메뉴에서 파일을
          업로드할 수 있습니다.
        </p>
      </div>
      <div>
        <p>
          업로드하신 축제 자료는 스탬프 팝 관리자가 확인한 후 플랫폼 셋팅을
          시작합니다.
        </p>
        <p>
          플랫폼 셋팅은 약 2주가 소요되오니, 자료 준비에 시간이 필요하신
          경우에는 축제일정과 셋팅 기간을 고려하여 자료를 업로드하여 주시기
          바랍니다.
        </p>
      </div>
      <div>
        <p>
          문의사항이 있는 경우 고객센터로 연락주시면 친절히 안내해드리겠습니다.
        </p>
      </div>
      <div class="email_chat">
        <p><span>이메일 : </span>festival@nextinnovation.kr</p>
        <p><span>채팅상담 : </span>우측 하단 문의하기 버튼 클릭</p>
      </div>
      <div class="btn_center">
        <base-button type="primary" @click="$emit('close')">확인</base-button>
      </div>
    </section>
  </div>
</template>

<script>
import BaseButton from "@/components/core/BaseButton.vue";
export default {
  components: {
    BaseButton,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/desktop.scss";
</style>
