import axios from "axios";

export const File = {
  download(url, name, type) {
    /**
     * url : 파일 다운로드 url
     * name : 파일명
     * type : 파일 확장자
     */
    const downalodFile = `${name}.${type}`;
    axios({
      url: url,
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      const responseUrl = window.URL.createObjectURL(new Blob([response.data]));
      const responseLink = document.createElement("a");
      responseLink.href = responseUrl;
      responseLink.setAttribute("download", downalodFile);
      document.body.appendChild(responseLink);
      responseLink.click();
    });
  },
};

export default File;
